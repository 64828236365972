
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
} from "@ionic/vue";
import {
  eyeOutline,
  eyeOffOutline,
  personOutline,
  phonePortraitOutline,
  mailOutline,
} from "ionicons/icons";
import { defineComponent, ref, reactive, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import settingsApp from "@/libs//settings";
import sessionApp from "@/libs/session";
import httpApp from "@/libs/http";

import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  sameAs,
  email,
} from "@vuelidate/validators";
import { useStore } from "@/store";

import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Edit Account",
  setup() {
    const { t, te, tm } = useI18n();
    const { createLoading, openToast } = useGlobalFunctions();
    const { getLocalStorageData } = useLocalStorage();
    const router = useRouter();
    const storeX = useStore();
    const isLoggedIn = ref(false);
    const customerData = ref(null);
    const editAccountData = reactive({
      firstname: "",
      lastname: "",
      email: "",
      changePassword: false,
      newPassword: "",
      newPasswordConfirm: "",
      showPassword: false,
    });
    const icons = reactive({
      eyeOutline,
      eyeOffOutline,
      personOutline,
      phonePortraitOutline,
      mailOutline,
    });
    const editAccountDataRules = {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      email: {
        email,
      },
      newPassword: {
        required: requiredIf(() => {
          return editAccountData.changePassword;
        }),
        minLength: minLength(7),
      },
      newPasswordConfirm: {
        required: requiredIf(() => {
          return editAccountData.changePassword;
        }),
        sameAsNewPassword: sameAs(toRef(editAccountData, "newPassword")),
      },
    };
    const vEditAccountData = useVuelidate(
      editAccountDataRules,
      editAccountData
    ) as any;

    function setCustomerData(customerDataParm: any) {
      customerData.value = customerDataParm;
      editAccountData.email = customerDataParm.email;
      editAccountData.firstname = customerDataParm.first_name;
      editAccountData.lastname = customerDataParm.last_name;
    }

    const saveAccountAction = async (event: any) => {
      event.preventDefault();
      vEditAccountData.value.$touch();
      if (vEditAccountData.value.$invalid) {
        return false;
      }
      const sentData = {
        firstname: editAccountData.firstname,
        lastname: editAccountData.lastname,
        email: editAccountData.email,
        change_password: "",
        password: "",
        confirmation: "",
      };
      if (editAccountData.changePassword) {
        sentData.change_password = "1";
        sentData.password = editAccountData.newPassword;
        sentData.confirmation = editAccountData.newPasswordConfirm;
      } else {
        delete sentData.change_password;
        delete sentData.password;
        delete sentData.confirmation;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("editCustomer"), sentData)
        .then(async (response: any) => {
          if (response.success) {
            sessionApp.getCustomerData().then(
              (response: any) => {
                storeX.commit("setCustomerData", response);
                editAccountData.newPassword = "";
                editAccountData.newPasswordConfirm = "";
                editAccountData.changePassword = false;
                openToast(t("done"), "success");
                router.push("/account/");
              },
              () => {
                router.push("/account/");
              }
            );
          } else {
            if (te("apiMsgs." + response.error.code)) {
              openToast(t("apiMsgs." + response.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
          loading.dismiss();
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error, "error");
        });
    };
    function ionViewWillEnter() {
      if (sessionApp.islogged()) {
        const sessionCustomerData = getLocalStorageData("customerData");
        isLoggedIn.value = true;
        setCustomerData(sessionCustomerData.account_data);
        storeX.commit("setCustomerData", sessionCustomerData);
      } else {
        storeX.commit("setCustomerData", null);
      }
    }

    return {
      t,
      tm,
      storeX,
      isLoggedIn,
      editAccountData,
      customerData,
      ionViewWillEnter,
      icons,
      vEditAccountData,
      saveAccountAction,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});

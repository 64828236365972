import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-10 m-auto" }
const _hoisted_6 = { class: "row d-block" }
const _hoisted_7 = { class: "col-md-3 float-start" }
const _hoisted_8 = { class: "col-md-9 float-start positionRelative account" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-12" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "card-body form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-lg-6" }
const _hoisted_17 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_AccountNavComponent = _resolveComponent("AccountNavComponent")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_AccountNavComponent)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.t("account.accountDetails")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_ion_list, { class: "formList" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.firstname.$errors.length,
                            }]),
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.icons.personOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      modelValue: _ctx.editAccountData.firstname,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editAccountData.firstname) = $event)),
                                      name: "firstname",
                                      clearInput: "true",
                                      inputmode: "text",
                                      type: "text",
                                      autocomplete: "given-name",
                                      placeholder: _ctx.t('signUp.firstName')
                                    }, null, 8, ["modelValue", "placeholder"])
                                  ]),
                                  _: 1
                                }, 8, ["class"]),
                                _createVNode(_component_ion_item, {
                                  class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.lastname.$errors.length,
                            }]),
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.icons.personOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      modelValue: _ctx.editAccountData.lastname,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editAccountData.lastname) = $event)),
                                      name: "lastname",
                                      inputmode: "text",
                                      type: "text",
                                      autocomplete: "family-name",
                                      placeholder: _ctx.t('signUp.lastName')
                                    }, null, 8, ["modelValue", "placeholder"])
                                  ]),
                                  _: 1
                                }, 8, ["class"]),
                                _createVNode(_component_ion_item, {
                                  class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.email.$errors.length,
                            }]),
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.icons.mailOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_input, {
                                      modelValue: _ctx.editAccountData.email,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editAccountData.email) = $event)),
                                      name: "email",
                                      inputmode: "email",
                                      type: "text",
                                      autocomplete: "email",
                                      placeholder: _ctx.t('signUp.email')
                                    }, null, 8, ["modelValue", "placeholder"])
                                  ]),
                                  _: 1
                                }, 8, ["class"]),
                                _createVNode(_component_ion_item, {
                                  lines: "none",
                                  class: "form-control"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("account.changePassword")), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_checkbox, {
                                      onIonChange: _cache[3] || (_cache[3] = ($event: any) => (
                                _ctx.editAccountData.changePassword =
                                  !_ctx.editAccountData.changePassword
                              )),
                                      name: "change_password",
                                      value: 1,
                                      checked: _ctx.editAccountData.changePassword,
                                      slot: "end"
                                    }, null, 8, ["checked"])
                                  ]),
                                  _: 1
                                }),
                                (_ctx.editAccountData.changePassword)
                                  ? (_openBlock(), _createBlock(_component_ion_item, {
                                      key: 0,
                                      lines: "none",
                                      class: _normalizeClass(["name-input form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.newPassword.$errors.length,
                            }])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: _ctx.editAccountData.newPassword,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editAccountData.newPassword) = $event)),
                                          placeholder: _ctx.t('account.newPassword'),
                                          name: "password",
                                          disabled: !_ctx.editAccountData.changePassword,
                                          inputmode: "text",
                                          type: 
                                !_ctx.editAccountData.showPassword
                                  ? 'password'
                                  : 'text'
                              
                                        }, null, 8, ["modelValue", "placeholder", "disabled", "type"]),
                                        _createVNode(_component_ion_button, {
                                          onClick: _cache[5] || (_cache[5] = ($event: any) => (
                                _ctx.editAccountData.showPassword =
                                  !_ctx.editAccountData.showPassword
                              )),
                                          fill: "clear",
                                          slot: "end",
                                          class: "endIconBtn"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: 
                                  !_ctx.editAccountData.showPassword
                                    ? _ctx.icons.eyeOutline
                                    : _ctx.icons.eyeOffOutline
                                
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["class"]))
                                  : _createCommentVNode("", true),
                                (_ctx.editAccountData.changePassword)
                                  ? (_openBlock(), _createBlock(_component_ion_item, {
                                      key: 1,
                                      class: _normalizeClass(["name-input form-control", {
                              'is-invalid':
                                _ctx.vEditAccountData.newPasswordConfirm.$errors
                                  .length,
                            }]),
                                      lines: "none"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: _ctx.editAccountData.newPasswordConfirm,
                                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editAccountData.newPasswordConfirm) = $event)),
                                          placeholder: 
                                _ctx.t('account.newPasswordConfirmation')
                              ,
                                          name: "confirmation",
                                          disabled: !_ctx.editAccountData.changePassword,
                                          inputmode: "text",
                                          type: 
                                !_ctx.editAccountData.showPassword
                                  ? 'password'
                                  : 'text'
                              
                                        }, null, 8, ["modelValue", "placeholder", "disabled", "type"]),
                                        _createVNode(_component_ion_button, {
                                          onClick: _cache[7] || (_cache[7] = ($event: any) => (
                                _ctx.editAccountData.showPassword =
                                  !_ctx.editAccountData.showPassword
                              )),
                                          fill: "clear",
                                          slot: "end",
                                          class: "endIconBtn"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: 
                                  !_ctx.editAccountData.showPassword
                                    ? _ctx.icons.eyeOutline
                                    : _ctx.icons.eyeOffOutline
                                
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["class"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  class: "btn btn-heading btn-block",
                                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.saveAccountAction($event)))
                                }, _toDisplayString(_ctx.t("account.save")), 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_17, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}